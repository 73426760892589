import * as React from "react"
import { useTranslation } from "react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <div className="dark" style={{ height: "375px", margin: "10vw" }}>
        <h4>{t("errortitle")}</h4>
        <h2>{t("errormessage")}</h2>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => (
  <Seo
    title="404: Not found"
    titleDE="404: Nicht gefunden"
    description="Oops, this page no longer exists."
    descriptionDE="Diese Seite existiert leider nicht mehr."
  />
)
